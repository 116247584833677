/*
Library Name: SassyOgre
Library URI: http://www.cleverogre.com/
Author: Clever Ogre
Author URI: http://www.cleverogre.com/
Description: Build basic Bootstrap stuff.
Version: 0.2

   ____                  ____
  / __/__ ____ ___ __ __/ __ \___ ________
 _\ \/ _ `(_-<(_-</ // / /_/ / _ `/ __/ -_)
/___/\_,_/___/___/\_, /\____/\_, /_/  \__/
                 /___/      /___/
*/

// Global Static Classes

.container {
    @include make-container();
}
.container-fluid {
    @include container-fixed();
}
.row {
    @include make-row();
}

// Grid Iterations

@include make-grid-columns(); // common styles
@include make-grid(xs); // xs grid
@media (min-width: $screen-sm-min) {
    @include make-grid(sm); // sm grid
}
@media (min-width: $screen-md-min) {
    @include make-grid(md); // md grid
}
@media (min-width: $screen-lg-min) {
    @include make-grid(lg); // lg grid
}
