/*
Library Name: SassyOgre
Library URI: http://www.cleverogre.com/
Author: CleverOgre
Author URI: http://www.cleverogre.com/
Description: Various mixin utilities written in SCSS. Compiled by D Cooper Dalrymple of CleverOgre in Pensacola, Florida
Version: 0.3

   ____                  ____
  / __/__ ____ ___ __ __/ __ \___ ________
 _\ \/ _ `(_-<(_-</ // / /_/ / _ `/ __/ -_)
/___/\_,_/___/___/\_, /\____/\_, /_/  \__/
                 /___/      /___/
*/

// Aspect Ratio

@mixin aspect-ratio($width, $height, $position-content: true) {
    position: relative;
    overflow-y: hidden;

    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }
    @if $position-content == true {
        >* {
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            bottom: 0;
            @include transform(translate3d(0, -50%, 0));
            width: 100%;
            min-height: 100%;
        }
    }
}

// Fades

@mixin fade($position: top, $color: #000, $size: 8px, $inner: false, $pseudo: before) {
    // $position can be either top, bottom, left, or right
    position: relative;

    &:#{$pseudo} {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        pointer-events: none;

        @if $inner == true {
            #{$position}: 0;
        } @else {
            #{$position}: -$size;
        }

        @if $position == top or $position == bottom {
            left: 0;
            right: 0;
            width: 100%;
            height: $size;
        } @else if $position == left or $position == right {
            top: 0;
            bottom: 0;
            height: 100%;
            width: $size;
        }

        $dir: $position;

        @if $inner == true {
            @if $position == top {
                $dir: bottom;
            } @else if $position == bottom {
                $dir: top;
            } @else if $position == left {
                $dir: right;
            } @else if $position == right {
                $dir: left;
            }
        }

        @include linear-gradient(to $dir, $color, fade-out($color, 1), $fallback: transparent);
    }
}

// Auto-Responsive (for single property changes)

@mixin auto-responsive($attr, $value-xl, $value-lg: $value-xl * 7 / 8, $value-md: $value-lg * 3 / 4, $value-sm: $value-lg / 2, $value-xs: $value-lg / 3) {
    #{$attr}: $value-xl;
    @media (max-width: $screen-lg-max) {
        #{$attr}: $value-lg;
    }
    @media (max-width: $screen-md-max) {
        #{$attr}: $value-md;
    }
    @media (max-width: $screen-sm-max) {
        #{$attr}: $value-sm;
    }
    @media (max-width: $screen-xs-max) {
        #{$attr}: $value-xs;
    }
}

// For SVG Clip Path

@mixin clip-path($clip) {
    -webkit-clip-path: $clip;
    -moz-clip-path: $clip;
    -ms-clip-path: $clip;
    clip-path: $clip;
}

// Avoid Break (for list columns children)

@mixin avoid-break() {
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    -o-column-break-inside: avoid;
    -ms-column-break-inside: avoid;
    column-break-inside: avoid;
    -moz-page-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid-column;
    display: table;
}

// Rem output with px fallback

@mixin font-size($sizeValue: 1) {
	font-size: ($sizeValue * 16) * 1px;
	font-size: $sizeValue * 1rem;
}

// Text stroke using text-shadow

@mixin text-stroke($width, $color) {
    $shadow: ();
    @for $i from -$width through $width {
        $shadow: append($shadow, #{$width} #{$i} 0px $color, comma);
        $shadow: append($shadow, #{-$width} #{$i} 0px $color, comma);
        $shadow: append($shadow, #{$i} #{$width} 0px $color, comma);
        $shadow: append($shadow, #{$i} #{-$width} 0px $color, comma);
    }
    text-shadow: $shadow;
}

// Touch Actions

@mixin user-drag($user-drag) {
    -webkit-user-drag: $user-drag;
    -moz-user-drag: $user-drag;
    -ms-user-drag: $user-drag;
    user-drag: $user-drag;
}

@mixin touch-callout($callout) {
    @include prefixer(touch-callout, $callout, webkit);
}

@mixin touch-action($action) {
    @include prefixer(touch-action, $action, ms)
}

@mixin tap-highlight-color($color) {
    @include prefixer(tap-highlight-color, $color, webkit);
}
