/*
File Name: _content.scss
  ____ _                      ___
 / ___| | _____   _____ _ __ / _ \  __ _ _ __ ___
| |   | |/ _ \ \ / / _ \ '__| | | |/ _` | '__/ _ \
| |___| |  __/\ V /  __/ |  | |_| | (_| | | |  __/
 \____|_|\___| \_/ \___|_|   \___/ \__, |_|  \___|
                                   |___/
*/

.aiowps-site-lockout-body-content {
    color: $gray-lighter;

    // Primary headings
    h1 {
        display: block;
        width: 100%;
        max-width: $container-md;
        height: auto;
        margin: 0 auto $gutter * 3 / 4;
        padding: 0 0 $gutter;
        &:last-child {
            margin-bottom: 0;
        }

        border-bottom: 10px solid fade-out($orange, 0.7);

        font-size: 0; // ($header-size-largerish + $header-size-larger) / 2;
        line-height: 0; // 1;
        color: transparent; // $orange;
        text-align: center;

        /*
        font-family: $font-family-sans-serif;
        font-weight: normal;
        font-style: normal;
        text-transform: uppercase;
        */

        img {
            display: inline-block;
            width: auto;
            max-width: 100%;
            height: auto;
            margin: 0 auto;
            padding: 0;
        }
    }
    h2 {
        display: block;
        width: 100%;
        max-width: $container-sm;
        height: auto;
        margin: 0 auto $gutter * 3 / 2;
        padding: 0;
        &:last-child {
            margin-bottom: 0;
        }

        font-family: $font-family-serif;
        font-size: $header-size;
        line-height: 1.5;
        font-weight: bold;
        font-style: normal;
        color: $gray-lighter;
        text-align: center;
    }

    // Secondary headings
    h3,
    h4,
    h5 {
        display: block;
        width: 100%;
        height: auto;
        margin: $gutter * 3 / 4 0;
        padding: 0;
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    h3 {
        font-family: $font-family-sans-serif;
        font-size: ($header-size-largerishish + $header-size-largerish) / 2;
        line-height: 1;
        font-weight: normal;
        font-style: normal;
        color: $orange;
    }
    h4 {
        font-family: $font-family-serif;
        font-size: $header-size;
        line-height: 1.25;
        font-weight: bold;
        font-style: normal;
        color: $gray-lighter;
    }
    h5 {
        font-family: $font-family-serif;
        font-size: $font-size-large;
        line-height: 1.5;
        font-weight: bold;
        font-style: normal;
        color: $orange;
    }

    // Standard paragraph stylings
    p {
        display: block;
        width: 100%;
        height: auto;
        margin: $gutter * 3 / 4 0;
        padding: 0;
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }

        font-family: $font-family-serif;
        font-size: $font-size-larger;
        line-height: 2;
        font-weight: 500;
        font-style: normal;
        text-align: left;
        vertical-align: baseline;
        color: inherit;
    }

    b {
        font-weight: bold;
    }
    i {
        font-style: italic;
    }
    em {
        font-weight: bold;
        font-style: italic;
    }
    strong {
        font-weight: bold;
    }

    img {
        display: block;
        width: auto;
        max-width: 100%;
        height: auto;
        float: none;
    }
    iframe {
        max-width: 100%;
    }

    a {
        display: inline;
        color: $orange;
        &:hover {
            color: $green-light;
            box-shadow: 0px 2px 0px $green-light;
        }
        &:active {
            color: $yellow-light;
            box-shadow: 0px 2px 0px $yellow-light;
        }
    }

    ul,
    ol {
        display: none;
        // I'll do this if needed...
    }

    table {
        display: none;
        // Same here...
    }

    blockquote {
        display: block;
        width: auto;
        height: auto;
        margin: $gutter * 2 0 $gutter * 2 84px + $gutter;
        padding: 0 0 0 $gutter * 3 / 2;
        border-left: 10px solid fade-out($orange, 0.7);
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }

        @media (max-width: $screen-xs-max) {
            margin-left: 0;
            padding-left: $gutter * 3 / 4;
        }
    }
}
