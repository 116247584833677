/*
File Name: _layout.scss
  ____ _                      ___
 / ___| | _____   _____ _ __ / _ \  __ _ _ __ ___
| |   | |/ _ \ \ / / _ \ '__| | | |/ _` | '__/ _ \
| |___| |  __/\ V /  __/ |  | |_| | (_| | | |  __/
 \____|_|\___| \_/ \___|_|   \___/ \__, |_|  \___|
                                   |___/
*/

// Colors
$gray-base: #000000;
$gray-darker: $gray-base;
$gray-darkerish: #1c0802;
$gray-dark: #231f20;
$gray: #231f20;
$gray-mid: #505050;
$gray-lightish: #999999;
$gray-light: #c2e3e1;
$gray-lighter: #fff;
$gray-lighterish: mix($gray-lightish, $gray-lighter);

$orange-base: #f89a31;
$orange-darkest: #c47f3d;
$orange-darker: #d47a15;
$orange-dark: #f0853b;
$orange: $orange-base;
$orange-light: #ffc298;

$green-base: #bdc8ba;
$green-dark: #93C7C4;
$green: $green-base;
$green-light: #c2e3e1;

$yellow-base: #f4cc11;
$yellow-dark: #46443b;
$yellow: $yellow-base;
$yellow-light: #f7ec76;

$brown-base: #898885;
$brown: $brown-base;

$blue-base: #2A384E;
$blue-darker: darken($blue-base, 15%);
$blue-dark: darken($blue-base, 10%);
$blue: $blue-base;
$blue-light: lighten($blue-base, 10%);
$blue-lighter: lighten($blue-base, 20%);
$blue-lightest: lighten($blue-base, 40%);

// Standard Bootstrap Colors
$bootstrap-primary: #0275d8;
$bootstrap-success: darken(#5cb85c, 10%);
$bootstrap-info: #5bc0de;
$bootstrap-warning: $orange; // #f0ad4e;
$bootstrap-danger: darken(#d9534f, 10%);
