/*
File Name: _typography.scss
  ____ _                      ___
 / ___| | _____   _____ _ __ / _ \  __ _ _ __ ___
| |   | |/ _ \ \ / / _ \ '__| | | |/ _` | '__/ _ \
| |___| |  __/\ V /  __/ |  | |_| | (_| | | |  __/
 \____|_|\___| \_/ \___|_|   \___/ \__, |_|  \___|
                                   |___/
*/

// Fonts
$font-family-sans-serif: 'Passion One', 'Open Sans', Arial, Helvetica, sans-serif;
$font-family-serif: 'Roboto Slab', Georgia, Times, "Time New Roman", serif;
$font-family-roboto: 'Roboto', $font-family-sans-serif;

$font-family: $font-family-serif; // $font-family-sans-serif;

$font-size-smallest: 10px;
$font-size-smaller: 12.8px;
$font-size-small: 15px;
$font-size: 17px;
$font-size-large: 19px;
$font-size-larger: 24px;

$header-size-smaller: 18px;
$header-size-small: 22px;
$header-size: 25px;
$header-size-large: 30px;
$header-size-largerishish: 36px;
$header-size-largerish: 60px;
$header-size-larger: 100px;
$header-size-largest: 144px;
