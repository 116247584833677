// Animated Icons
// --------------------------

/*
.#{$fa-css-prefix}-spin {
    @include animation(fa-spin 2s infinite linear);
}

.#{$fa-css-prefix}-pulse {
    @include animation(fa-spin 1s infinite steps(8));
}
*/

@keyframes fa-spin {
    0% {
        @include transform(rotate(0deg));
    }
    100% {
        @include transform(rotate(360deg));
    }
}
