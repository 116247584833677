/*
Library Name: SassyOgre
Library URI: http://www.cleverogre.com/
Author: Clever Ogre
Author URI: http://www.cleverogre.com/
Description: SCSS adaptation of essential Bootstrap mixins. Compiled by D Cooper Dalrymple of CleverOgre in Pensacola, Florida
Version: 0.2

   ____                  ____
  / __/__ ____ ___ __ __/ __ \___ ________
 _\ \/ _ `(_-<(_-</ // / /_/ / _ `/ __/ -_)
/___/\_,_/___/___/\_, /\____/\_, /_/  \__/
                 /___/      /___/
*/

/*-- VARIABLES --*/

/* Grid */

// Media Queries

$screen-xs:                  480px !default;
$screen-xs-min:              $screen-xs !default;

$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;

$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;

$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;

$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;

// Columns

$grid-columns:               12 !default;
$gutter:                     32px !default;

// Container

$container-sm:               (720px + $gutter) !default;
$container-md:               (940px + $gutter) !default;
$container-lg:               (1140px + $gutter) !default;

/*-- GENERAL --*/

@mixin clearfix() {
    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}

@mixin center-block() {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

// Reset Box Sizing to border-box (requires compass for box-sizing mixin)
* {
    box-sizing: border-box;
}
*:before,
*:after {
    box-sizing: border-box;
}

/*-- GRID --*/

// Primary Mixins

@mixin container-fixed($l-gutter: $gutter) {
    margin-right: auto;
    margin-left: auto;
    padding-left:  floor(($l-gutter / 2));
    padding-right: ceil(($l-gutter / 2));
    @include clearfix;
}

@mixin make-row($l-gutter: $gutter) {
    margin-left:  ceil(($l-gutter / -2));
    margin-right: floor(($l-gutter / -2));
    @include clearfix;
}

@mixin make-xs-column($columns, $l-gutter: $gutter) {
    position: relative;
    float: left;
    width: percentage(($columns / $grid-columns));
    min-height: 1px;
    padding-left:  ($l-gutter / 2);
    padding-right: ($l-gutter / 2);
}
@mixin make-xs-column-offset($columns) {
    margin-left: percentage(($columns / $grid-columns));
}
@mixin make-xs-column-push($columns) {
    left: percentage(($columns / $grid-columns));
}
@mixin make-xs-column-pull($columns) {
    right: percentage(($columns / $grid-columns));
}

@mixin make-sm-column($columns, $l-gutter: $gutter) {
    position: relative;
    min-height: 1px;
    padding-left:  ($l-gutter / 2);
    padding-right: ($l-gutter / 2);

    @media (min-width: $screen-sm-min) {
        float: left;
        width: percentage(($columns / $grid-columns));
    }
}
@mixin make-sm-column-offset($columns) {
    @media (min-width: $screen-sm-min) {
        margin-left: percentage(($columns / $grid-columns));
    }
}
@mixin make-sm-column-push($columns) {
    @media (min-width: $screen-sm-min) {
        left: percentage(($columns / $grid-columns));
    }
}
@mixin make-sm-column-pull($columns) {
    @media (min-width: $screen-sm-min) {
        right: percentage(($columns / $grid-columns));
    }
}

@mixin make-md-column($columns, $l-gutter: $gutter) {
    position: relative;
    min-height: 1px;
    padding-left:  ($l-gutter / 2);
    padding-right: ($l-gutter / 2);

    @media (min-width: $screen-md-min) {
        float: left;
        width: percentage(($columns / $grid-columns));
    }
}
@mixin make-md-column-offset($columns) {
    @media (min-width: $screen-md-min) {
        margin-left: percentage(($columns / $grid-columns));
    }
}
@mixin make-md-column-push($columns) {
    @media (min-width: $screen-md-min) {
        left: percentage(($columns / $grid-columns));
    }
}
@mixin make-md-column-pull($columns) {
    @media (min-width: $screen-md-min) {
        right: percentage(($columns / $grid-columns));
    }
}

@mixin make-lg-column($columns, $l-gutter: $gutter) {
    position: relative;
    min-height: 1px;
    padding-left:  ($l-gutter / 2);
    padding-right: ($l-gutter / 2);

    @media (min-width: $screen-lg-min) {
        float: left;
        width: percentage(($columns / $grid-columns));
    }
}
@mixin make-lg-column-offset($columns) {
    @media (min-width: $screen-lg-min) {
        margin-left: percentage(($columns / $grid-columns));
    }
}
@mixin make-lg-column-push($columns) {
    @media (min-width: $screen-lg-min) {
        left: percentage(($columns / $grid-columns));
    }
}
@mixin make-lg-column-pull($columns) {
    @media (min-width: $screen-lg-min) {
        right: percentage(($columns / $grid-columns));
    }
}

// Framework Builder

@mixin make-grid-columns($i: 1, $list: ".col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}") {
    @for $i from (1 + 1) through $grid-columns {
        $list: "#{$list}, .col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}";
    }
    #{$list} {
        position: relative;
        min-height: 1px;
        padding-left:  ceil(($gutter / 2));
        padding-right: floor(($gutter / 2));
    }
}

@mixin float-grid-columns($class, $i: 1, $list: ".col-#{$class}-#{$i}") {
    @for $i from (1 + 1) through $grid-columns {
        $list: "#{$list}, .col-#{$class}-#{$i}";
    }
    #{$list} {
        float: left;
    }
}

@mixin calc-grid-column($index, $class, $type) {
    @if ($type == width) and ($index > 0) {
        .col-#{$class}-#{$index} {
            width: percentage(($index / $grid-columns));
        }
    }
    @if ($type == push) and ($index > 0) {
        .col-#{$class}-push-#{$index} {
            left: percentage(($index / $grid-columns));
        }
    }
    @if ($type == push) and ($index == 0) {
        .col-#{$class}-push-0 {
            left: auto;
        }
    }
    @if ($type == pull) and ($index > 0) {
        .col-#{$class}-pull-#{$index} {
            right: percentage(($index / $grid-columns));
        }
    }
    @if ($type == pull) and ($index == 0) {
        .col-#{$class}-pull-0 {
            right: auto;
        }
    }
    @if ($type == offset) {
        .col-#{$class}-offset-#{$index} {
            margin-left: percentage(($index / $grid-columns));
        }
    }
}

@mixin loop-grid-columns($columns, $class, $type) {
    @for $i from 0 through $columns {
        @include calc-grid-column($i, $class, $type);
    }
}

@mixin make-grid($class) {
    @include float-grid-columns($class);
    @include loop-grid-columns($grid-columns, $class, width);
    @include loop-grid-columns($grid-columns, $class, pull);
    @include loop-grid-columns($grid-columns, $class, push);
    @include loop-grid-columns($grid-columns, $class, offset);
}

// Primary Classes

@mixin make-container() {
    @include container-fixed;

    @media (min-width: $screen-sm-min) {
        width: $container-sm;
    }
    @media (min-width: $screen-md-min) {
        width: $container-md;
    }
    @media (min-width: $screen-lg-min) {
        width: $container-lg;
    }
}

@mixin make-container-fluid() {
    @include container-fixed;
}

/*-- Text --*/

@mixin hide-text() {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

@mixin text-hide() {
    @include hide-text;
}

/*-- Images --*/

@mixin img-responsive($display: block) {
    display: $display;
    max-width: 100%;
    height: auto;
}

@mixin img-retina($file-1x, $file-2x, $width-1x, $height-1x) {
    background-image: url(if($bootstrap-sass-asset-helper, twbs-image-path("#{$file-1x}"), "#{$file-1x}"));

    @media
    only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (   min--moz-device-pixel-ratio: 2),
    only screen and (     -o-min-device-pixel-ratio: 2/1),
    only screen and (        min-device-pixel-ratio: 2),
    only screen and (                min-resolution: 192dpi),
    only screen and (                min-resolution: 2dppx) {
        background-image: url(if($bootstrap-sass-asset-helper, twbs-image-path("#{$file-2x}"), "#{$file-2x}"));
        background-size: $width-1x $height-1x;
    }
}
