/*
File Name: _mixins.scss
  ____ _                      ___
 / ___| | _____   _____ _ __ / _ \  __ _ _ __ ___
| |   | |/ _ \ \ / / _ \ '__| | | |/ _` | '__/ _ \
| |___| |  __/\ V /  __/ |  | |_| | (_| | | |  __/
 \____|_|\___| \_/ \___|_|   \___/ \__, |_|  \___|
                                   |___/
*/

@include keyframes(buttonRipple) {
    0% {
        @include transform(scale3d(1, 1, 1));
        opacity: 1;
    }
    59% {
        @include transform(scale3d(1.2, 1.2, 1));
        opacity: 0;
    }
    60% {
        @include transform(scale3d(0.75, 0.75, 1));
        opacity: 0;
    }
    100% {
        @include transform(scale3d(1, 1, 1));
        opacity: 1;
    }
}

@mixin button($fore-color: $yellow, $back-color: $gray-base) {
    display: inline-block;
    width: auto;
    height: auto;
    margin: 0 auto;
    padding: 20px 60px;

    color: $fore-color;
    font-style: italic;
    line-height: 1;
    font-size: $font-size-larger;
    font-family: $font-family-roboto;
    text-decoration: none;
    background-color: $back-color;
    text-align: center;
    text-transform: none;
    font-weight: normal;

    position: relative;
    z-index: 2;

    border: 1px solid $fore-color;

    &:hover {
        background-color: $fore-color;
        color: $back-color;
    }
}

@mixin button-solid($fore-color: $yellow, $back-color: $gray-base) {
    display: inline-block;
    width: auto;
    height: auto;
    margin: 0 auto;
    padding: 20px 60px;

    font-style: italic;
    line-height: 1;
    font-size: $font-size-larger;
    font-family: $font-family-roboto;
    text-decoration: none;
    text-align: center;
    text-transform: none;
    font-weight: normal;

    position: relative;
    z-index: 2;

    border: 1px solid transparent;

    @include button-solid-color($fore-color, $back-color);
}

@mixin button-solid-color($fore-color: $yellow, $back-color: $gray-base) {
    color: $fore-color;
    background-color: $back-color;
    border-color: $back-color;
    &:hover {
        background-color: $fore-color;
        border-color: $fore-color;
        color: $back-color;
    }
}

@mixin icon-set($pseudo: before, $selector: false, $use-hover-color: true) {
    // List of Icon Properties
    $icons: (
        (folder, $fa-var-folder-open, false, normal, false),
        (phone, $fa-var-phone, false, normal, false),
        (fax, $fa-var-fax, false, normal, false),
        (email, $fa-var-envelope, false, normal, false),
        (location, $fa-var-map-marker, false, normal, false),
        (website, $fa-var-globe, false, normal, false),
        (facebook, $fa-var-facebook-f, #3b5998, normal, true),
        (googleplus, $fa-var-google-plus-g, #dd4b39, normal, true),
        (linkedin, $fa-var-linkedin-in, #007bb5, normal, true),
        (houzz, $fa-var-houzz, #73ba37, normal, true),
        (instagram, $fa-var-instagram, #e95950, normal, true),
        (twitter, $fa-var-twitter, #55acee, normal, true),
        (pinterest, $fa-var-pinterest, #cb2027, normal, true),
        (youtube, $fa-var-youtube, #bb0000, normal, true),
        (tumblr, $fa-var-tumblr, #32506d, normal, true),
        (reddit, $fa-var-reddit, #ff4500, normal, true),
        (spotify, $fa-var-spotify, #1ED760, normal, true),
    );

    // Initialize Selector
    @if ($selector != false) {
        $selector: " #{$selector}";
    } @else {
        $selector: "";
    }

    // Global Settings
    &#{$selector}:#{$pseudo} {
        @include fa-icon();
    }

    // Build Icons
    @each $icon in $icons {
        $name: nth($icon, 1);
        $fa-var: nth($icon, 2);
        $hcolor: nth($icon, 3);
        $weight: nth($icon, 4);
        $brand: nth($icon, 5);

        &.#{$name}#{$selector}:#{$pseudo} {
            content: fa-content($fa-var);
            font-weight: $weight;
            @if $brand == true {
                font-family: 'Font Awesome 5 Brands';
            }
        }
        @if ($use-hover-color == true && $hcolor != false) {
            &.#{$name}#{$selector}:hover:#{$pseudo} {
                color: $hcolor;
            }
        }
    }
}

@mixin nav-defaults() {
    display: block;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    line-height: 1;
    ul {
        display: block;
        width: 100%;
        height: auto;
        list-style: none;
        margin: 0;
        padding: 0;
        line-height: 1;
        >li {
            display: inline-block;
            width: auto;
            height: auto;
            position: relative;
            margin: 0;
            padding: 0;
            line-height: 1;
            >a {
                display: block;
                width: auto;
                height: auto;
            }
            >ul {
                display: none;
            }
        }
    }
}
