/*
File Name: _accessibility.scss
  ____ _                      ___
 / ___| | _____   _____ _ __ / _ \  __ _ _ __ ___
| |   | |/ _ \ \ / / _ \ '__| | | |/ _` | '__/ _ \
| |___| |  __/\ V /  __/ |  | |_| | (_| | | |  __/
 \____|_|\___| \_/ \___|_|   \___/ \__, |_|  \___|
                                   |___/
*/

// Text meant only for screen readers.

.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;

	&:focus {
		//background-color: $color_background-screen;
		border-radius: 3px;
		box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
		clip: auto !important;
		//color: $color_text-screen;
		display: block;
		@include font-size(0.875);
		font-weight: bold;
		height: auto;
		left: 5px;
		line-height: normal;
		padding: 15px 23px 14px;
		text-decoration: none;
		top: 5px;
		width: auto;
		z-index: 100000; /* Above WP toolbar. */
	}
}
