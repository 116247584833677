/*
File Name: _layout.scss
  ____ _                      ___
 / ___| | _____   _____ _ __ / _ \  __ _ _ __ ___
| |   | |/ _ \ \ / / _ \ '__| | | |/ _` | '__/ _ \
| |___| |  __/\ V /  __/ |  | |_| | (_| | | |  __/
 \____|_|\___| \_/ \___|_|   \___/ \__, |_|  \___|
                                   |___/
*/

html {
    min-height: 100%;
}

body {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: $gray-darker !important;
}

.aiowps-site-lockout-body {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

    font-size: 0;
    line-height: 0;
    color: transparent;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .aiowps-site-lockout-body-content {
        @include display(flex);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .aiowps-site-lockout-box {
        display: block;
        width: 100%;
        max-width: none;
        height: auto;
        margin: 0;
        padding: $gutter * 2 0;
        position: relative;
        z-index: 10;

        background-color: $gray-darker;
        background-repeat: no-repeat;
        background-position: top center;
        background-size: 100% auto;
        //background-image: url(https://cleverogre.com/wp-content/themes/cave/assets/img/mockup-curtain.png);
        border: none;

        min-height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        @include flex(1 1 auto);

        &:before,
        &:after {
            content: '';
            display: block;
            width: 100%;
            height: auto;
            margin: 0;
            padding: percentage(1284 / 1920) 0 0;

            position: absolute;
            top: 0;
            right: 0;
            bottom: auto;
            left: 0;
        }
        &:before {
            z-index: 10;
            //@include linear-gradient(to bottom, fade-out($gray-darker, 1) 0%, fade-out($gray-darker, 0) 100%, $fallback: fade-out($gray-darker, 1));
            background-image: -webkit-linear-gradient(top, transparent 0%,#000 100%), url(https://cleverogre.com/wp-content/themes/cave/assets/img/mockup-curtain.png);
            background-image: linear-gradient(to bottom,transparent 0%,#000 100%), url(https://cleverogre.com/wp-content/themes/cave/assets/img/mockup-curtain.png);
        }
        &:after {
            z-index: 20;
            background-color: transparent;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% auto;
            background-image: url(https://cleverogre.com/wp-content/themes/cave/assets/img/mockup-texture.png);
        }

        // Ultrawide
        @media (min-width: 1921px) {
            &:before,
            &:after {
                height: 100%;
                padding: 0;
                position: fixed;
                bottom: 0;
                background-attachment: fixed;
            }
            &:before {
                background-size: cover;
            }
        }

        @media (max-width: $screen-xs-max) {
            padding-top: 0;
            padding-bottom: 0;
            background-image: none;
            &:before,
            &:after {
                content: none;
                display: none;
                visibility: hidden;
            }
        }

        .aiowps-site-lockout-msg {
            @include make-container();
            padding: $gutter * 2 $gutter $gutter;
            position: relative;
            z-index: 30;
            background: fade-out($gray-darker, 0.25);

            color: inherit;
            font-size: inherit;
            font-weight: inherit;
            text-align: inherit;

            @media (max-width: $screen-xs-max) {
                padding-left: $gutter / 2;
                padding-right: $gutter / 2;
                padding-bottom: $gutter * 2;
            }
        }
    }
}
